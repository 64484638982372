<template>
  <div class="step-content">
    <template v-if="isPoint">
      <div class="d-block">
        <div class="title">{{ $t('questions.step_5.title') }}</div>
        <SelectBox @update-val="updateVal"
                   type="radio"
                   ref="selectBox"
                   :check-data="$t('questions.step_5.checkbox')"/>
      </div>
    </template>
    <template v-else>
      <div class="d-block">
        <div class="title">{{ $t('questions.step_5.title_1') }}</div>
        <SelectBox @update-val="updateVal"
                   type="radio"
                   ref="selectBox"
                   :check-data="$t('questions.step_6.checkbox')"/>
      </div>
    </template>
    <div class="d-flex justify-content-center">
      <RestartBtn/>
      <div v-if="isPoint" class="btn" @click="goTo" :class="{disabled: !selected.length}">{{
          $t('questions.step_5.btnText')
        }}
      </div>
      <router-link v-else class="btn" to="6" :class="{disabled: !selected.length}">{{
          $t('questions.step_5.btnText')
        }}
      </router-link>
    </div>
  </div>
</template>

<script>
import SelectBox from "../components/SelectBox";

export default {
  name: 'Step_5',
  components: {
    SelectBox
  },
  data() {
    return {
      selected: '',
      isPoint: true
    }
  },
  mounted() {
    if (sessionStorage.getItem(this.$route.name) !== 'undefined' && sessionStorage.getItem(this.$route.name)) {
      this.selected = JSON.parse(sessionStorage.getItem(this.$route.name))
      this.$refs.selectBox.setVal(this.selected)
    } else {
      this.selected = ''
    }
    if (sessionStorage.getItem('isPoint')) this.isPoint = sessionStorage.getItem('isPoint') === 'true';
    this.$root.$on('isPoint', data => {
      this.isPoint = data
      this.selected = ''
    })
  },
  methods: {
    updateVal(val) {
      this.selected = val
    },
    goTo() {
      if (this.selected.includes('No')) {
        this.$router.push('6')
        this.isPoint = true
      } else {
        this.isPoint = false
        this.selected = ''
      }
      sessionStorage.setItem('isPoint', this.isPoint)
    }
  }
}
</script>
